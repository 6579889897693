<template lang="pug">
CanvasModal.download-modal(
  title="Generate Thumbnails for Permutation"
  :isShow="isShow"
  @closeOverlay="closeDownloadPreview"
)
  template(#top)
    .design-permutation-options.tab-2
      .tab__head
        button.btn-change-tab.tab__item(
          type="button"
          @click="changeTab('choose')"
          :class="{ 'is-active': activeTab === 'choose' }"
          v-html="`Choose thumbnail`"
        )
        button.btn-change-tab.tab__item(
          type="button"
          @click="changeTab('preview')"
          :class="{  'is-active': activeTab === 'preview' }"
          v-html="`Preview thumbnail`"
        )

  .download-modal__container(
    @dragenter="dragEnter"
  )

    template(v-if="isDragEnter")
      DropZone(
        @dropZoneDrop="drop"
        @dropZoneDragLeave="dragLeave"
      )

    .download-modal__option(
      v-if="activeTab === 'choose'"
    )
      .tab-2
        p.title Slide to choose video frame for preview
        .slider-container
          .range-wrapper
            span.range-before
            input.slider.js-slider(
              type="range"
              step="0.1"
              min="0"
              :max="getCurrentSceneDuration"
              v-model.number="currentTime")
          .slider-container__wrapper
            .slider-container__number(
              :style="timeStyleObject"
            )
              i.icon.icon-camera
              span {{ `${getCurrentTime} sec` }}

        .download-modal__buttons
          template(
            v-if="!isDownloadingImage"
          )
            BaseButton(
              @click="processRender('jpg')"
              isPrimary=true
              isTall=true
              isFullWidth=true
            ) Generate Thumbnail for preview

          img.downloading-image.preloader-image(
            v-if="isDownloadingImage"
            src="@/assets/images/preloader/offeo_preloader.svg"
          )

    .download-modal__preview-thumbnail(
      v-if="activeTab === 'preview'"
    )
      .preview-tab-assets
        ul
          li.upload-your-own(
            @click="$refs.uploadYourOwnFile.click()"
          )
            input(
              v-show="false"
              type="file"
              ref="uploadYourOwnFile"
              accept="image/*"
              @change="fileSelected"
            )

            template(v-if="customHeroImage")
              img(
                :src="customHeroImage"
              )
              span.list-text Upload your own

            template(v-else)
              i.icon.icon-plus
              span.list-text Upload your own
          li(
            v-for="(item, index) in previewAssets"
            :key="`preview-assets-${index}`"
            :class="{'is-active' : item === activeHeroImage}"
            @click="toggleHeroImage(item)"
          )
            img(
              :src="getImage(item)"
            )
            span.list-text {{getImageName(item)}}

    .project-preview(
      ref="templatePreviewWrapper"
    )
      BasePreloader.project-preview__preloader(
        :class="{ 'is-show': isLoading }"
      )

      .project-preview__container
        .project-preview__canvas(
          :style="canvasStyle"
        )
          CanvasScene(
            :elements="[...getCanvasElements].reverse()"
            sceneClass="scenes__canvas"
            ref="test"
            :background="getCanvasBackground"
            animationTimeline="mainTimeline"
            :duration="getCurrentSceneDuration"
            :id="getActiveSceneId"
          )

</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Linear, TimelineLite } from 'gsap/TweenMax';
import { windowWidth } from '@/assets/scripts/variables';

import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';
import DropZone from '@/components/ProjectCanvas/PageOverlay/DropZone.vue';

import renderMixin from '@/components/mixins/render-mixins';
import PreviewAssets from '@/assets/data/preview-assets.json';

export default {
  name: 'TemplatePreview',
  mixins: [renderMixin],
  props: {
    isCaptureFrame: { type: Boolean, default: false },
    scaleDimension: { type: Number, default: -1 },
  },
  components: {
    DropZone,
    CanvasScene,
  },
  data() {
    return {
      isDragEnter: false,
      downloadModalSize: {
        width: 520,
        height: 520,
      },
      minSize: 520,
      minSizeSmall: 440,
      minSizeSmallest: 280,
      timelineBar: new TimelineLite(),
      isShow: true,
      activeTab: 'choose',
      isLoading: true,
      customHeroImage: '',
      activeHeroImage: '',
      previewAssets: PreviewAssets.withoutBg,
    };
  },
  computed: {
    ...mapState('canvasElements', [
      'timelineSpeed',
      'temporaryHeroImageUrl',
    ]),
    ...mapGetters([
      'isDownloadPreviewOpened',
      'getIsAllScenesPlaying',
      'getCurrentTime',
    ]),
    ...mapGetters('userData', [
      'isFreeUser',
    ]),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getScenesElementsById',
      'getCanvasSize',
      'getAllScenesTimeline',
      'getAllScenesDuration',
      'getProjectDetails',
      'getCanvasSize',
      'getHeroElement',
      'getActiveElements',
      'getActiveSceneId',
      'getCanvasBackground',
      'getCanvasElements',
      'getCanvasElementsAnimation',
      'getCanvasSize',
      'getCurrentSceneDuration',
      'getMainTimeline',
      'getProjectDetails',
      'getSaveHistory',
      'getCopiedElements',
    ]),
    ...mapGetters('exportDetails', [
      'getExportDetailStates',
    ]),
    ...mapState([
      'isTemplatePreviewOpened',
    ]),
    currentTime: {
      get() {
        return this.getCurrentTime;
      },
      set(value) {
        this.$store.commit('setCurrentTime', value);
      },
    },
    canvasModalStyle() {
      const styleObject = {};

      if (this.isCaptureFrame) {
        styleObject.width = `${this.getCanvasSize.width}px`;
        styleObject.height = `${this.getCanvasSize.height}px`;
      }

      return styleObject;
    },
    canvasStyle() {
      const styleObject = {};

      const { width, height } = this.downloadModalSize;

      let sceneScale;
      let canvasWidth = this.getCanvasSize.width;
      let canvasHeight = this.getCanvasSize.height;

      if (this.scaleDimension > 1) {
        sceneScale = this.scaleDimension;
      } else {
        sceneScale = height / this.getCanvasSize.height;
        if (this.getCanvasSize.width * sceneScale >= width) {
          sceneScale = width / this.getCanvasSize.width;
        }
      }

      if (this.isCaptureFrame) {
        if (sceneScale < 1) {
          sceneScale = 1;
        } else {
          canvasWidth *= this.scaleDimension;
          canvasHeight *= this.scaleDimension;
        }
      }

      let offsetLeft = canvasWidth * sceneScale / 2;
      let offsetTop = canvasHeight * sceneScale / 2;

      // maximize full with without scaling the wrapper
      if (this.isCaptureFrame) {
        offsetLeft = 0;
        offsetTop = 0;
        styleObject.left = 0;
        styleObject.top = 0;
      }

      styleObject.marginLeft = `-${offsetLeft}px`;
      styleObject.marginTop = `-${offsetTop}px`;
      styleObject.width = `${canvasWidth}px`;
      styleObject.height = `${canvasHeight}px`;
      styleObject.transform = `scale(${sceneScale})`;

      if (!this.isCaptureFrame && sceneScale > 1) {
        styleObject.marginLeft = 0;
        styleObject.marginTop = 0;
        styleObject.transformOrigin = 'top center';
        styleObject.left = 0;
        styleObject.right = 0;
        styleObject.top = 0;
        styleObject.bottom = 0;
      }

      return styleObject;
    },
    timeStyleObject() {
      const styleObject = {};
      const percentage = this.getCurrentTime / this.getCurrentSceneDuration;

      styleObject.left = `${percentage * 100}%`;

      return styleObject;
    },
  },
  methods: {
    ...mapMutations([
      'setCurrentAllScenesTime',
      'isAllScenesPlaying',
      'setIsRendering',
      'setShowUpgradeSubscription',
      'setIsTemplatePreviewOpened',
    ]),
    ...mapMutations('canvasElements', [
      'setTemporaryHeroImageUrl',
    ]),
    changeTab(value) {
      this.activeTab = value;
    },
    getElements(scene) {
      if (!scene || !scene.id) return [];
      return [...this.getScenesElementsById(scene.id)].reverse();
    },
    updateDownloadModalSize() {
      // todo: remove timeout
      setTimeout(() => {
        const { width, height } = this.getCanvasSize;
        console.log('test', this.$refs.templatePreviewWrapper);
        // for capture frame toggle full width and height
        this.downloadModalSize.width = this.isCaptureFrame ? width : this.$refs.templatePreviewWrapper.clientWidth;
        this.downloadModalSize.height = this.isCaptureFrame ? height : this.$refs.templatePreviewWrapper.clientHeight;
      }, 10);
    },
    currentSceneStartTime(index) {
      // eslint-disable-next-line
      if (index == 0) return 0;
      let duration = 0;
      this.getScenes.forEach((scene, sceneIndex) => {
        if (sceneIndex < index) {
          duration += scene.duration;
        }
      });
      return duration;
    },
    previousTransition(index) {
      // eslint-disable-next-line
      if (index == 0) return {};
      return this.getScenes[index - 1].transition;
    },
    playAllScenes() {
      /**
       * DON't alter id of play button '#hidden-play-button' as it will be used as a trigger handler for playing in rendering servers
       * document.getElementById('hidden-play-button').click()
       */

      let currentAnimationTime = this.getCurrentAllScenesTime;
      if (currentAnimationTime < 0.1) currentAnimationTime = 0.05;

      this.timelineBar.seek(this.getCurrentAllScenesTime);
      this.getAllScenesTimeline.seek(this.getCurrentAllScenesTime);

      if (this.getIsAllScenesPlaying) {
        this.isAllScenesPlaying(false);
        this.timelineBar.timeScale(this.timelineSpeed).pause();
        this.getAllScenesTimeline.timeScale(this.timelineSpeed).pause();
        this.$root.$emit('pauseAudio');
      } else {
        this.isAllScenesPlaying(true);
        this.timelineBar.timeScale(this.timelineSpeed).seek(currentAnimationTime).play();
        this.getAllScenesTimeline.timeScale(this.timelineSpeed).seek(currentAnimationTime).play();

        const completeTime = () => {
          this.resetCanvasPreview();
        };

        this.getAllScenesTimeline.eventCallback('onComplete', completeTime);

        this.$root.$emit('playAudio');
      }
    },
    setTimelineControllerTimeline() {
      const timeObj = { time: 0 };
      const totalDuration = this.getAllScenesDuration;
      this.setCurrentAllScenesTime(0);

      this.timelineBar.kill().clear();
      this.timelineBar.clear();
      this.timelineBar.addLabel('Start', 0);
      this.timelineBar.pause();
      this.getAllScenesTimeline.clear();
      this.getAllScenesTimeline.restart();
      this.getAllScenesTimeline.addLabel('Start', 0);
      this.getAllScenesTimeline.pause();
      this.$root.$emit('canvas-element-animation-updated');

      const updateTime = () => {
        this.setCurrentAllScenesTime(parseFloat(timeObj.time.toFixed(1)));
      };

      const completeTime = () => {
        this.resetCanvasPreview();
      };

      this.timelineBar
        .to(timeObj, totalDuration, {
          time: `+=${totalDuration}`,
          onUpdate: updateTime,
          onComplete: completeTime,
          ease: Linear.easeNone,
        }, 'Start');

      // added timeout to make sure the tweenmax already set
      // seek to 0.05 cause on 0 everything is overlapping
      setTimeout(() => {
        this.timelineBar.seek(0.05);
        this.getAllScenesTimeline.seek(0.05);
      }, 200);
    },
    resetCanvasPreview() {
      this.timelineBar.pause();
      this.timelineBar.seek(0);
      this.getAllScenesTimeline.pause();
      this.getAllScenesTimeline.seek(0);
      this.$root.$emit('canvas-element-animation-updated');
      this.setCurrentAllScenesTime(0);
      this.isAllScenesPlaying(false);
      this.$root.$emit('pauseAudio');
    },
    closeDownloadPreview() {
      this.customHeroImage = '';
      this.activeHeroImage = '';
      this.setTemporaryHeroImageUrl('');
      this.setIsTemplatePreviewOpened(false);
    },
    getImage(item) {
      return `${process.env.VUE_APP_CDN_ASSETS}/smart-create/${item}`;
    },
    getImageName(item) {
      return item.split('.').slice(0, -1).join('.').split('_')
        .join(' ');
    },
    toggleHeroImage(item) {
      if (this.temporaryHeroImageUrl && this.activeHeroImage === item) {
        // remove
        this.activeHeroImage = '';
        this.setTemporaryHeroImageUrl('');
      } else {
        this.activeHeroImage = item;
        this.setTemporaryHeroImageUrl(this.getImage(item));
      }
    },
    dragEnter() {
      this.isDragEnter = true;
      if (this.activeTab !== 'preview') this.activeTab = 'preview';
    },
    dragLeave() {
      this.isDragEnter = false;
    },
    fileSelected(event) {
      const blobFile = this.getImageUrlFromFile(event.target.files[0]);
      this.customHeroImage = blobFile;
      this.activeHeroImage = blobFile;
      this.setTemporaryHeroImageUrl(blobFile);
      this.$refs.uploadYourOwnFile.file = '';
    },
    drop(file) {
      this.dragLeave();
      if (!file) return;

      const blobFile = this.getImageUrlFromFile(file);
      this.customHeroImage = blobFile;
      this.activeHeroImage = blobFile;
      this.setTemporaryHeroImageUrl(blobFile);
    },
    getImageUrlFromFile(file) {
      return (typeof file === 'object') ? URL.createObjectURL(file) : '';
    },
  },
  beforeMount() {
    this.isShow = this.isTemplatePreviewOpened;

    if (window.screen.width <= windowWidth.fbRequirement) {
      this.downloadModalSize.width = this.minSizeSmallest;
      this.downloadModalSize.height = this.minSizeSmallest;
    } else if (window.screen.width <= windowWidth.smallLaptopSize) {
      this.downloadModalSize.width = this.minSizeSmall;
      this.downloadModalSize.height = this.minSizeSmall;
    } else {
      this.downloadModalSize.width = this.minSize;
      this.downloadModalSize.height = this.minSize;
    }
  },
  mounted() {
    this.updateDownloadModalSize();
    this.setTimelineControllerTimeline();
    // hide the loader after 1 sec
    setTimeout(() => {
      this.setCurrentAllScenesTime(this.getAllScenesDuration / 2);
      this.isLoading = false;
    }, 1000);

    this.$root.$on('playAllScenes', () => {
      this.playAllScenes();
    });
  },
  watch: {
    getCurrentAllScenesTime() {
      if (!this.$store.state.isAllScenesPlaying) {
        let currentAnimationTime = this.getCurrentAllScenesTime;
        if (currentAnimationTime < 0.1) currentAnimationTime = 0.05;

        this.getAllScenesTimeline.seek(currentAnimationTime);
      }
    },
    activeTab(val) {
      if (val === 'image') {
        this.isAllScenesPlaying(false);
        this.timelineBar.pause();
        this.getAllScenesTimeline.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.download-modal__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .tab__head {
    .tab__item {
      max-width: 150px;
    }
  }

  .tab__body {
    text-align: left;

    .tab__item {
      padding: 20px;
    }
  }

  .tab-2 {
    height: 100%;
  }
  .title{
    text-align: left;
    font-size: 1.25em;
  }
}

.download-modal__option,
.project-preview {
  height: 520px;

  @include smallest {
    height: 440px;
  }

  @include fb-requirement {
    height: 280px;
  }
}

.project-preview {
  position: relative;
  overflow: hidden;
  width: 520px;

  @include smallest {
    width: 440px;
  }

  @include fb-requirement {
    width: 280px;
  }

  .scenes__canvas {
    transform-origin: center center;
  }
}

.project-preview__container {
  width: 100%;
  height: 100%;
}

.project-preview__canvas {
  position: relative;
  display: inline-block;
  user-select: none;
  overflow: hidden;
  transform-origin: top left;
  left: 50%;
  top: 50%;
  background: transparent;
  transition: background 0.35s ease-in-out;

  .scene {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .drr.active {
    &::before {
      display: none;
    }
  }
}

.project-preview__preloader {
  position: absolute;
  z-index: 4;
  width: 100%;
  background: $lightBg;
  transition: opacity 0.25s ease-in-out;

  &:not(.is-show) {
    pointer-events: none;
    opacity: 0;
  }
}

.project-preview__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
  }

  .slider-container {
    width: 300px;
    margin: 0 auto 10px;
  }
}

.btn-play-project-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 78px;
  cursor: pointer;
  z-index: 2;
  color: $blue;

  &:hover {
    opacity: 0.9;
  }

  &.is-playing {
    opacity: 0;
  }

  &:not(.is-playing) {
    & ~ .project-preview__container {
      opacity: 0.4;
    }
  }
}

.download-modal__option {
  padding-right: 20px;
  width: 50%;

  .title {
    margin-top: 0;
    font-weight: 600;
  }

  .slider-container {
    height: 48px;
    position: relative;
    margin-bottom: 40px;

    .range-wrapper {
      max-width: 100%;

      .range-before {
        top: 22px;
      }
    }

    .range-before,
    .range-before::after {
      height: 4px;
    }

    input[type=range] {
      margin: 0;
      height: 48px;

      &::-moz-range-thumb,
      &::-ms-thumb {
        height: 48px;
        width: 48px;
      }

      &::-webkit-slider-thumb {
        margin-top: -24px;
        height: 48px;
        width: 48px;
      }
    }
  }

  .slider-container__wrapper {
    position: absolute;
    height: 48px;
    width: calc(100% - 48px);
    top: -3px;
    left: 0;
    pointer-events: none;
    z-index: 2;
  }

  .slider-container__number {
    position: absolute;
    width: 48px;
    display: block;
    top: 0;
    left: 0;

    .icon {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      background: $blue;
      border-radius: $componentBorderRadius;
    }

    span {
      position: absolute;
      top: 50px;
      width: 56px;
      margin-left: -3px;
      text-align: center;
      font-size: 0.875em;
    }
  }
}

.download-modal__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    max-width: 100%;
  }

  .downloading-image{
    max-width: 13%;
  }
}

.design-permutation-options {
  text-align: center;
  display: flex;
  margin: -10px -30px;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 60px);
  overflow: auto;

  .tab__item {
    min-width: 220px;
  }

  button {
    background: transparent;
    cursor: pointer;

    &:not(:disabled):hover {
      &.btn-prev::before {
        border-right-color: $blue;
        transform: translateX(-5px);
      }
      &.btn-next::before {
        border-left-color: $blue;
        transform: translateX(5px);
      }
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      transition: transform 0.15s ease-in-out
    }

    &:disabled::before {
      opacity: 0.25;
    }

    &.btn-prev::before {
      border-right: 12px solid $darkGrey;
    }

    &.btn-next::before {
      border-left: 12px solid $darkGrey;
    }
  }
}

.download-modal__preview-thumbnail{
  max-width: 50%;
}
.preview-tab-assets{
  ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 500px;
    overflow-y: auto;

    li{
      width: calc(100% / 3 - 20px);
      border: 1px solid #E1E4E8;
      margin: 10px;
      border-radius: 3px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor:pointer;

      &.is-active{
        border: 2px solid $blue;
      }

      img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      .icon{
        font-size: 2em;
      }

      .list-text{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        color:$darkGrey900;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }

      &.upload-your-own{
        justify-content: flex-end;
        padding: 10px;

        .icon{
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
